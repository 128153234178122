<template>
  <CForm ref="form" class="needs-validation"   >
   
    <CRow>
      <CCol col="6">Kargo Şirketi Seçiniz:</CCol>
      <CCol col="6">
        <CSelect
            placeholder="Tüm Şirketler"
            horizontal
            :options="shippingCompanies"
            :value.sync="data.shippingCompany"
        >
        </CSelect>
      </CCol>
    </CRow>
  </CForm>
</template>

<script>
export default{
  name: "PrintGiftCard",
  props:{
    validated: Boolean,
    params: Object,
    actionType: String,
  },
  watch:{
    params: function(val){
      this.data = {...val};
    },
    actionType:  function(val){
      if(val == 'create'){
        this.data = {...this.params};
      }
    },
  },
  computed: {
    shippingCompanies: function(){
      let data = []
      this.$store.getters.shippingCompanyList.map(r => data.push({value:r.id, label: r.name}))
      return data
    }
      
  },
  data: function(){
    return{
      data: this.params ? {...this.params} : {},

    }
  }
}
</script>